export enum ApiAction {
	ACTIVATE = 'activate',
	ADD = 'add',
	ARCHIVE = 'archive',
	CLONE = 'clone',
	CREATE = 'create',
	DELETE = 'delete',
	DELETE_ALL = 'deleteAll',
	DETAILS = 'details',
	DOWNLOAD = 'download',
	EMPTY = 'empty',
	EXIST = 'exist',
	EXPORT = 'export',
	EXTEND = 'extend',
	FILE_HISTORY = 'file-history',
	FIND = 'find',
	HIDE = 'hide',
	INVITE = 'invite',
	MARK = 'mark',
	MOVE = 'move',
	NOTIFY = 'notify',

	ORDER = 'order',
	PRINT = 'print',

	PRINT_JOB_ACTIVITY = 'print-jobs-activity',
	PROJECT_ARCHIVE = 'project-archive',
	PROJECT_DATA = 'project-data',
	PROJECT_LOGS = 'project-logs',
	PROJECT_PRINT_JOBS = 'project-print-jobs',
	PROJECT_USERS = 'project-users',

	READ = 'read',
	READ_ARCHIVES = 'read-archives',
	REGISTER_COMPANY = 'register-company',

	REMOVE = 'remove',
	REPLACE = 'replace',

	RESTORE = 'restore',
	RESTORE_ALL = 'restoreAll',
	SEND = 'send',
	SEND_LINK = 'send-link',
	SHOW = 'show',
	TOGGLE_VISIBILITY = 'toggleVisibility',
	UPDATE = 'update',
	UPDATE_ACCESS = 'update-access',
	UPLOAD = 'upload',
	VERIFY = 'verify',
}
